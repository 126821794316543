/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.scss';

// Composables
import {createVuetify} from 'vuetify';
import {createVueI18nAdapter} from 'vuetify/locale/adapters/vue-i18n';
import {useI18n} from 'vue-i18n';
import i18n from './i18n';

const customTheme = {
    dark: false,
    colors: {
        customer: '#9ec09d',
        'customer-secondary': '#1e5151',
        'on-customer': '#ffffff'
    }
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    locale: {
        adapter: createVueI18nAdapter({i18n, useI18n}),
    },
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme
        }
    }
});
