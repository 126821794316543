/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import {createRouter, createWebHistory} from 'vue-router';
import StartScreenComponent from '@/components/pages/StartScreenComponent';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: 'home',
            params: {},
        }
    },
    {
        name: 'home',
        path: '/',
        component: StartScreenComponent
    },
    {
        name: 'inboundItems.create',
        path: '/inbound-items/create/:originTypeId',
        component: () => import('@/components/pages/inboundItems/CreateInbound.vue'),
        props: route => ({
            title: 'inboundItemsRawMaterialsTitle',
            originTypeId: route.params.originTypeId
        })
    },
    {
        name: 'inboundItems.create-direct-packed',
        path: '/inbound-items/direct-packed/create',
        component: () => import('@/components/pages/inboundItems/CreateInbound.vue'),
        props: {
            title: 'inboundItemsProductsTitle',
            isDirectPacked: true
        }
    },
    {
        name: 'production.planning',
        path: '/production/planning',
        component: () => import('@/components/pages/production/planning/PlanningOverview.vue')
    },
    {
        name: 'production.locations',
        path: '/production/locations',
        component: () => import('@/components/pages/production/locations/SelectLocation.vue')
    },
    {
        name: 'production.selectType',
        path: '/production/locations/:productionLocationId/type',
        component: () => import('@/components/pages/production/locations/SelectProductionType.vue'),
        props: route => ({productionLocationId: route.params.productionLocationId})
    },
    {
        name: 'production.input',
        path: '/production/locations/:productionLocationId/input',
        component: () => import('@/components/pages/production/input/InputOverview.vue'),
        props: route => ({productionLocationId: route.params.productionLocationId})
    },
    {
        name: 'production.output',
        path: '/production/locations/:productionLocationId/output',
        component: () => import('@/components/pages/production/output/OutputOverview.vue'),
        props: route => ({productionLocationId: route.params.productionLocationId})
    },
    {
        name: 'production.output.add',
        path: '/production/locations/:productionLocationId/output/:productionOrderLineId',
        component: () => import('@/components/pages/production/output/CreateOutput.vue'),
        props: route => ({
            productionLocationId: route.params.productionLocationId,
            productionOrderLineId: route.params.productionOrderLineId
        })
    },
    {
        name: 'production.output.addStock',
        path: '/production/locations/:productionLocationId/output/stock',
        component: () => import('@/components/pages/production/output/CreateStock.vue'),
        props: route => ({productionLocationId: route.params.productionLocationId})
    },
    {
        name: 'deliver.deliver',
        path: '/deliver/deliver',
        component: () => import('@/components/pages/deliver/deliver/DeliverStepper.vue'),
    },
    {
        name: 'deliver.return',
        path: '/deliver/return',
        component: () => import('@/components/pages/deliver/return/ReturnProductionOutput.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

export default router;
