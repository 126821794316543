/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify';
import i18n from '../plugins/i18n';
import filters from '../plugins/filters';
import pinia from '@/store';
import router from '../router';
import lodash from 'lodash';
import './vue-api-query';

window._ = lodash;

export function registerPlugins(app) {
    app.use(vuetify)
        .use(i18n)
        .use(pinia)
        .use(router);

    app.config.globalProperties.$filters = filters;
}
