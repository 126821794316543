// Utilities
import {defineStore} from 'pinia';
import EmployeeLogin from '@/models/EmployeeLogin';
import EmployeeLogout from '@/models/EmployeeLogout';
import axios from 'axios';
import Employee from '@/models/Employee';
import {useWorkplaceStore} from '@/store/stores/workplace';

export const useEmployeeStore = defineStore('employee', {
    state: () => ({
        employee: null,
        logoutTimer: null,
    }),

    getters: {
        /**
         * Get the current employee.
         *
         * @param state
         */
        getEmployee(state) {
            return state.employee;
        },

        /**
         * Get the initials of the employee.
         *
         * @param state
         */
        getEmployeeInitials(state) {
            return state.employee?.name.split(' ').map(word => word[0]).join('');
        }
    },

    actions: {
        /**
         * Set the employee and start the logout timer.
         * - The delay is based on the configured workplace.
         */
        setEmployee(employee) {
            this.employee = employee;

            this.setLogoutTimeout();
        },

        /**
         * Set the logout timeout.
         * - Don't start a timeout if none is configured.
         */
        setLogoutTimeout() {
            if (!useWorkplaceStore().workplace?.portal_timeout) {
                return;
            }

            this.logoutTimer = setTimeout(this.logout, useWorkplaceStore().workplace.portal_timeout)
        },

        /**
         * Reset the logout timeout.
         */
        resetTimeout() {
            if (!useWorkplaceStore().workplace || !this.employee) {
                return
            }

            clearTimeout(this.logoutTimer);
            this.setLogoutTimeout();
        },

        /**
         * To authenticate your SPA, your SPA's "login" page should first make a request to the /sanctum/csrf-cookie endpoint to initialize CSRF protection for the application.
         * https://laravel.com/docs/10.x/sanctum#csrf-protection
         *
         * @returns {Promise<axios.AxiosResponse<any>>}
         */
        initializeCSRFProtection() {
            return axios.get(import.meta.env.VITE_API_URL.replace('/api', '') + '/sanctum/csrf-cookie')
        },

        /**
         * Login with a pincode.
         */
        login(pincode) {
            return this.initializeCSRFProtection().then(e => {
                // Once the CSRF cookie is set, you can make subsequent requests
                return new EmployeeLogin({'pincode': pincode}).save().then(result => {
                    this.setEmployee(result);
                    return Promise.resolve(this.employee);
                });
            })
        },

        /**
         * Logout.
         */
        logout() {
            if (!this.employee) {
                return;
            }

            return new EmployeeLogout({}).save().then(() => {
                this.employee = null;
            });
        },

        /**
         * Get the current employee (of the current session).
         * @returns {Promise<*>}
         */
        getCurrentEmployee() {
            return Employee.custom('/employee').first()
                .then((result) => {
                    this.setEmployee(result);
                    return Promise.resolve(this.employee);
                })
                .catch((e) => {
                    if (e.response?.status === 401) {
                        // Unauthenticated; no problem
                        return;
                    }

                    throw e;
                });
        }
    },
});
