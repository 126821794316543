// Utilities
import {defineStore} from 'pinia';
import OriginType from '@/models/OriginType';

export const useOriginTypeStore = defineStore('originType', {
    actions: {
        /**
         * Fetch origin types from the API
         */
        async fetchOriginTypes() {
            return OriginType.get();
        },

        /**
         * Fetch origin type by ID from the API
         *
         * @param {number} id
         */
        async fetchOriginTypeById(id) {
            return OriginType.find(id);
        }
    },
});
