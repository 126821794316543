<script setup>
const emit = defineEmits(['clicked', 'backspace']);
defineProps(['disabled'])
</script>

<template>
    <v-container class="numeric-keypad">
        <v-row no-gutters>
            <v-col v-for="i in [7, 8, 9]">
                <v-sheet class="border">
                    <v-btn
                        variant="flat"
                        :disabled="disabled"
                        @click="emit('clicked', i)"
                    >
                        {{ i }}
                    </v-btn>
                </v-sheet>
            </v-col>

            <v-responsive class="w-100"></v-responsive>

            <v-col v-for="i in [4, 5, 6]">
                <v-sheet class="border">
                    <v-btn
                        variant="flat"
                        :disabled="disabled"
                        @click="emit('clicked', i)"
                    >
                        {{ i }}
                    </v-btn>
                </v-sheet>
            </v-col>

            <v-responsive class="w-100"></v-responsive>

            <v-col v-for="i in [1, 2, 3]">
                <v-sheet class="border">
                    <v-btn
                        variant="flat"
                        :disabled="disabled"
                        @click="emit('clicked', i)"
                    >
                        {{ i }}
                    </v-btn>
                </v-sheet>
            </v-col>

            <v-responsive class="w-100"></v-responsive>

            <v-col>
                <v-sheet class="border">
                    <v-btn
                        disabled
                        variant="flat"
                    />
                </v-sheet>
            </v-col>

            <v-col>
                <v-sheet class="border">
                    <v-btn
                        variant="flat"
                        :disabled="disabled"
                        @click="emit('clicked', 0)"
                    >
                        0
                    </v-btn>
                </v-sheet>
            </v-col>

            <v-col>
                <v-sheet class="border">
                    <v-btn
                        variant="flat"
                        :disabled="disabled"
                        @click="emit('backspace')"
                    >
                        <v-icon
                            icon="mdi-backspace"
                        ></v-icon>
                    </v-btn>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>
