// Utilities
import {defineStore} from 'pinia';
import axios from 'axios';

export const useAppStore = defineStore('app', {
    state: () => ({
        loading: false,
    }),

    getters: {
        /**
         * Get loading status
         *
         * @return {boolean}
         */
        isLoading() {
            return this.loading;
        },
    },

    actions: {
        /**
         * Set loading status
         *
         * @param {boolean} loading
         */
        setLoading(loading) {
            this.loading = loading;
        },

        /**
         * Print labels for given ids
         *
         * @param {String} endpoint
         * @param {Object} params
         */
        printLabels(endpoint, params) {
            axios.get(
                import.meta.env.VITE_API_URL + '/' + endpoint,
                {
                    responseType: 'arraybuffer',
                    params: params,
                },
            ).then((response) => {
                let blob = new Blob([response.data], {type: 'text/html'});

                let printWindow = window.open(window.URL.createObjectURL(blob), '', 'width=588,height=832,location=no,scrollbars=no');
                if (!printWindow) {
                    return;
                }

                printWindow.onload = () => {
                    printWindow.print();

                    setTimeout(function () {
                        printWindow.close();
                    }, 100);
                };
            });
        },

        /**
         * Check if a property has an error.
         * @param errors
         * @param property
         * @returns {boolean}
         */
        hasErrors(errors, property) {
            if (!errors) {
                return false;
            }

            return errors.hasOwnProperty(property) && errors[property].length > 0;
        },

        /**
         * Get the errors of a property.
         * @param errors
         * @param propertyName
         * @returns {[]}
         */
        getErrors(errors, propertyName) {
            if (!this.hasErrors(errors, propertyName)) {
                return [];
            }

            return errors[propertyName];
        }
    },
});
