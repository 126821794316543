/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import {registerPlugins} from '@/plugins';

// Sentry
import * as Sentry from '@sentry/vue';

// Components
import App from './App.vue';

// Composables
import {createApp} from 'vue';

const app = createApp(App);

if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_APP_VERSION,
    });
}

registerPlugins(app);

app.mount('#app');
