import dayjs from 'dayjs';

const filters = {
    /**
     * Convert a value to a date format.
     * @param value
     * @returns {string|null}
     */
    toDate(value) {
        if (!value) {
            return null;
        }

        return dayjs(value).format('DD-MM-YYYY');
    },

    /**
     * Convert a value to a date time format.
     * @param value
     * @returns {string|null}
     */
    toDateTime(value) {
        if (!value) {
            return null;
        }

        return dayjs(value).format('DD-MM-YYYY HH:mm');
    }
}

export default filters;
