import axios from 'axios';
import {useAppStore} from '@/store/stores/app';
import {useEmployeeStore} from '@/store/stores/employee';
import {Model} from 'vue-api-query';
import {showNotification} from '@/store/notification';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

/**
 * Handle HTTP errors.
 */
axios.interceptors.response.use(
    response => {
        // Hide loading
        useAppStore().setLoading(false);

        return response;
    },
    error => {
        useAppStore().setLoading(false);

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        if (error.response) {
            if (error.response.status === 401) {
                useEmployeeStore().employee = null;
                return Promise.reject(error);
            }

            if (error.response.status === 404) {
                return Promise.reject(error);
            }

            if (error.response.data.message) {
                // User error of backend
                showNotification(error.response.data.message, 'error');

                return Promise.reject(error);
            }
        }

        // Others
        showNotification(error.message, 'error');

        return Promise.reject(error);
    });

/**
 * Add a request interceptor
 */
axios.interceptors.request.use(
    (config) => {
        // Show loading
        useAppStore().setLoading(true);

        // Add Api Auth Token to every api request
        config.headers['Auth-Token'] = import.meta.env.VITE_API_AUTH_TOKEN;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Model.$http = axios;
