import {Model as BaseModel} from 'vue-api-query';

export default class Model extends BaseModel {
    /**
     * Base URL for API calls.
     * @returns {any}
     */
    baseURL() {
        return import.meta.env.VITE_API_URL;
    }

    /**
     * Configure request options.
     * @param config
     * @returns {*}
     */
    request(config) {
        return this.$http.request(config);
    }
}
