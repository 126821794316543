// Utilities
import {defineStore} from 'pinia';
import Workplace from '@/models/Workplace';
import axios from 'axios';

export const useWorkplaceStore = defineStore('workplace', {
    state: () => ({
        workplace: null,
        token: localStorage.getItem('workplaceToken')
    }),

    actions: {
        /**
         * Find a workplace by token.
         * @param token
         * @returns {Promise<void>}
         */
        findWorkplace(token) {
            return Workplace.find(token).then((workplace) => {
                this.workplace = workplace;
                localStorage.setItem('workplaceToken', workplace.token);
                this.setWorkplaceTokenHeader(workplace.token);

                return Promise.resolve();
            }).catch(e => {
                if (e.response.status === 404) {
                    localStorage.removeItem('workplaceToken');
                }

                return Promise.reject(e);
            });
        },

        /**
         * Add the workplace token to the header.
         */
        setWorkplaceTokenHeader (token) {
            axios.defaults.headers.common['X-WorkplaceToken'] = token;
        },
    },
});
