// Translations provided by Vuetify
import {nl as nlVuetify} from 'vuetify/locale';
import nl from '../locales/nl';
import {createI18n} from 'vue-i18n';

const messages = {
    nl: {
        $vuetify: {
            ...nlVuetify,
        },
        ...nl
    },
};

const i18n = createI18n({
    legacy: false, // Vuetify does not support the legacy mode of vue-i18n
    locale: 'nl',
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE,
    messages,
});

export default i18n;
