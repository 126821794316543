<script setup>
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import {useOriginTypeStore} from '@/store/stores/originType';
import {useAppStore} from '@/store/stores/app';
import OriginType from '@/models/OriginType';
import LoaderComponent from '@/components/helpers/LoaderComponent.vue';

// Disposables
const {t} = useI18n();
const router = useRouter();
const originTypeStore = useOriginTypeStore();
const appStore = useAppStore();

// Data
let originTypes = ref(null);
let pages = ref([]);
let inboundItemPages = ref([]);
let productionPages = ref([]);
let inventoryManagementPages = ref([]);

// Data initialization
originTypeStore.fetchOriginTypes().then((result) => {
    originTypes.value = result;
    setPages();
});

// Computed
let isLoading = computed(() => appStore.isLoading);

// Methods
const setPages = () => {
    const ownSupply = originTypes.value.find(originType => originType.id === OriginType.OWN_SUPPLY());
    const buyIn = originTypes.value.find(originType => originType.id === OriginType.BUY_IN());

    productionPages.value = [
        {
            title: t('production'),
            icon: 'mdi-factory',
            navigate: () => router.push({name: 'production.locations'})
        },
        {
            title: t('planning'),
            icon: 'mdi-calendar',
            navigate: () => router.push({name: 'production.planning'})
        },
    ];

    inboundItemPages.value = [
        {
            title: t('ownSupply'),
            icon: 'mdi-tractor',
            navigate: () => router.push({
                name: 'inboundItems.create',
                params: {
                    originTypeId: ownSupply.id
                }
            }),
        },
        {
            title: t('buyIn'),
            icon: 'mdi-truck-delivery',
            navigate: () => router.push({
                name: 'inboundItems.create',
                params: {
                    originTypeId: buyIn.id
                }
            }),
        },
        {
            title: t('directPacked'),
            icon: 'mdi-archive-marker',
            navigate: () => router.push({name: 'inboundItems.create-direct-packed'}),
        },
    ];

    inventoryManagementPages.value = [
        {
            title: t('deliver'),
            icon: 'mdi-human-dolly',
            navigate: () => router.push({
                name: 'deliver.deliver'
            }),
        },
        {
            title: t('returnOutput'),
            icon: 'mdi-arrow-u-left-top',
            navigate: () => router.push({
                name: 'deliver.return'
            }),
        },
    ];
}
</script>

<template>
    <v-container
        class="scrollable fill-height"
        fluid
    >
        <template v-if="!isLoading">
            <start-screen-category
                :title="t('inboundItems')"
                :pages="inboundItemPages"
            ></start-screen-category>

            <start-screen-category
                :title="t('production')"
                :pages="productionPages"
            ></start-screen-category>

            <start-screen-category
                :title="t('stock')"
                :pages="inventoryManagementPages"
            ></start-screen-category>
        </template>

        <loader-component
            v-else
            class="mx-auto"
        />
    </v-container>
</template>
