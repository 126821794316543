<script setup>
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useWorkplaceStore} from '@/store/stores/workplace';

const {t} = useI18n();
const workplaceStore = useWorkplaceStore();

let isSubmitting = ref(false);
let token = ref(null);
let show = ref(true);
let errorMessages = ref([]);

/**
 * Save workplace token.
 */
const saveWorkplaceToken = () => {
    isSubmitting.value = true;
    errorMessages.value = [];

    workplaceStore.findWorkplace(token.value).catch((error) => {
        if (error.response.status === 404) {
            errorMessages.value.push(t('tokenNotFound'));
        }
    }).finally(() => {
        isSubmitting.value = false;
    });
}
</script>

<template>
    <v-dialog
        id="workplace-token-modal"
        width="auto"
        persistent
        v-model="show"
    >
        <v-card>
            <template v-slot:title>
                <span>{{ t('tokenRequired') }}</span>
            </template>

            <template v-slot:text>
                <v-text-field
                    v-model="token"
                    density="comfortable"
                    single-line
                    autofocus
                    ref="input"
                    autocomplete="off"
                    :placeholder="t('token')"
                    :disabled="isSubmitting"
                    :error="errorMessages.length > 1"
                    :error-messages="errorMessages"
                    @keyup.enter="saveWorkplaceToken"
                />

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        :text="t('save')"
                        variant="tonal"
                        :disabled="!token || isSubmitting"
                        @click="saveWorkplaceToken"
                    ></v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>
