<script setup>
import {useEmployeeStore} from '@/store/stores/employee';
import {computed} from 'vue';

const employeeStore = useEmployeeStore();

// Computed properties
const employeeInitials = computed(() => employeeStore.getEmployeeInitials);

/**
 * Refresh the application
 */
const refresh = () => {
    window.location.reload();
};
</script>

<template>
    <v-app>
        <v-main class="bg-grey-lighten-3">
            <v-container fluid class="h-screen">
                <v-sheet rounded="lg" class="h-100">
                    <suspense>
                        <template #default>
                            <router-view/>
                        </template>
                        <template #fallback>
                            <loader-component/>
                        </template>
                    </suspense>
                </v-sheet>

                <v-btn
                    class="floating-refresh-button"
                    variant="elevated"
                    size="large"
                    icon="mdi-refresh"
                    @click="refresh"
                />

                <v-btn
                    class="floating-logout-button"
                    variant="elevated"
                    size="large"
                    :icon="true"
                    @click="employeeStore.logout"
                >
                    {{ employeeInitials }}
                </v-btn>
            </v-container>
        </v-main>
    </v-app>
</template>
