<script setup>
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import NumericKeypad from '@/components/helpers/NumericKeypad';
import {useEmployeeStore} from '@/store/stores/employee';

const {t} = useI18n();
const employeeStore = useEmployeeStore();

let isSubmitting = ref(false);
let pincode = ref('');
let show = ref(true);
let errorMessages = ref([]);

// Computed properties
const errorTitle = computed(() => {
    return errorMessages.value.join(' ');
});

/**
 * Add a digit to the pincode
 *
 * @param {String|Number} digit
 */
const addDigitToPincode = (digit) => {
    if (pincode.value.length >= 4) {
        return;
    }

    pincode.value += digit;
}

/**
 * Login.
 */
const login = () => {
    isSubmitting.value = true;
    errorMessages.value = [];

    employeeStore.login(pincode.value).catch((error) => {
        pincode.value = '';
        errorMessages.value = error.response.data.errors.login
    }).finally(() => {
        isSubmitting.value = false;
    });
}
</script>

<template>
    <v-dialog
        id="workplace-token-modal"
        width="auto"
        persistent
        v-model="show"
    >
        <v-card>
            <template v-slot:title>
                <span>{{ t('loginEmployee') }}</span>
            </template>

            <template v-slot:text>
                <v-otp-input
                    focus-all
                    :length="4"
                    v-model="pincode"
                    @finish="login"
                    :error="errorMessages.length > 0"
                    :error-messages="errorMessages"
                />

                <v-alert
                    v-if="errorMessages.length"
                    class="mb-4"
                    color="error"
                    :title="errorTitle"
                ></v-alert>

                <div
                    class="text-center mb-2"
                    :class="{'hidden': !isSubmitting}"
                >
                    <v-progress-circular
                        indeterminate
                    ></v-progress-circular>
                </div>

                <numeric-keypad
                    :disabled="isSubmitting"
                    @clicked="addDigitToPincode"
                    @backspace="pincode = pincode.slice(0, -1)"
                />
            </template>
        </v-card>
    </v-dialog>
</template>
