import Model from './Model';

export default class Workplace extends Model {
    /**
     * Resource of this model.
     *
     * @return {string}
     */
    resource() {
        return 'workplaces';
    }
}
