<template>
    <v-app
        v-on:touchstart="employeeStore.resetTimeout"
    >
        <template v-if="isSettingUp">
            <loader-component class="mt-4"/>
        </template>

        <template v-else>
            <app-workplace-token-dialog
                v-if="!workplaceStore.workplace"
            />

            <app-employee-dialog
                v-else-if="!employeeStore.employee"
            />

            <v-main v-else>
                <Home/>
            </v-main>
        </template>

        <v-snackbar
            v-model="notification.show"
            :timeout="notification.timeout"
            :color="notification.color"
            location="top center"
        >
            {{ notification.text }}
        </v-snackbar>

        <small id="version-number">
            {{ versionNumber }}
        </small>
    </v-app>
</template>

<script setup>
import Home from '@/components/Home';
import {computed, ref, watch} from 'vue';
import {notification} from '@/store/notification';
import AppEmployeeDialog from '@/AppEmployeeLoginDialog';
import AppWorkplaceTokenDialog from '@/AppWorkplaceTokenDialog';
import {useEmployeeStore} from '@/store/stores/employee';
import {useWorkplaceStore} from '@/store/stores/workplace';

const employeeStore = useEmployeeStore();
const workplaceStore = useWorkplaceStore();
const localNotification = ref(notification);
let isSettingUp = ref(true);

// Computed properties
const versionNumber = computed(() => import.meta.env.VITE_APP_VERSION);

// Watch for changes in the global notification state and update the local ref
watch(localNotification, (newValue) => {
    localNotification.value = newValue;
});

/**
 * Initialize the workplace.
 *
 * @returns {Promise<void>}
 */
const initWorkplace = async () => {
    if (!workplaceStore.token) {
        isSettingUp.value = false;

        return Promise.reject();
    }

    return workplaceStore.findWorkplace(workplaceStore.token);
}

/**
 * Initialize the employee.
 *
 * @returns {Promise<void>}
 */
const initEmployee = async () => employeeStore.getCurrentEmployee();

initWorkplace()
    .then(() => initEmployee())
    .finally(() => isSettingUp.value = false);
</script>
