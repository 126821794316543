import {ref} from 'vue';

const notification = ref({
    show: false,
    text: '',
    color: 'success',
    timeout: 2000,
});

/**
 * Show a snackbar notification.
 * @param text
 * @param color
 * @param timeout
 */
const showNotification = (text, color = 'success', timeout = 5000) => {
    notification.value.show = true;
    notification.value.text = text;
    notification.value.color = color;
    notification.value.timeout = timeout;
};

export {notification, showNotification};
