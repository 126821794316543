import Model from './Model'

export default class OriginType extends Model {
    static OWN_SUPPLY = () => 'ownSupply';
    static BUY_IN = () => 'buyIn';

    resource() {
        return 'origin-types';
    }
}
